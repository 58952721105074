import api from './api';

/**
 * 
 * @param {*} text 
 * @param {*} sourceLangCode 
 * @param {*} targetLangCode 
 * @returns {object} 
 * @example
 * {
 *    "sourceLanguageCode": "en",
 *    "targetLanguageCode": "en",
 *    "text": "Hi there!"
 * }
 */
export const postTranslateText = async (text, sourceLangCode = 'auto', targetLangCode = 'en') => {
  if (!text) {
    throw new Error('Must provide a value for text to use this service');
  }

  const data = {
    text,
    sourceLangCode,
    targetLangCode,
  };

  try {
    const translateResponse = await api.post('/v1/participant/translate', data);
    return translateResponse.data;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
    throw error;
  }
};

const TranslateAPI = {
  postTranslateText,
};

export default TranslateAPI;