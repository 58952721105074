import React, { useEffect, useRef, useState } from 'react';
import './chatroom.css';
import Message from './message.js';
import { postTranslateText } from '../utils/translateAPI';
import { addChat, useGlobalState } from '../store/state';


const Chatroom = (props) => {

  const [Chats] = useGlobalState('Chats');
  const currentContactId = useGlobalState('currentContactId');
  const [newMessage, setNewMessage] = useState("");
  const [languageTranslate] = useGlobalState('languageTranslate');
  const [languageOptions] = useGlobalState('languageOptions');
  const agentUsername = "AGENT";
  const messageEl = useRef(null);
  const input = useRef(null);


  function getKeyByValue(object) {
    const contactLanguageCode = languageTranslate[currentContactId[0]];
    if (!contactLanguageCode) {
      return;
    }
    return Object.keys(object).find(key => object[key] === contactLanguageCode);
  }

  const sendMessage = async (session, content) => {
    try {
      const awsSdkResponse = await session.sendMessage({
        contentType: "text/plain",
        message: content
      });
      return awsSdkResponse;
    } catch(err) {
      console.error('[chatroom.js] -> sendMessage:', err);
    } 
  }

  useEffect(() => {

    // this ensures that the chat window will auto scoll to ensure the more recent message is in view
    if (messageEl) {
      messageEl.current.addEventListener('DOMNodeInserted', event => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
    }
    // this ensure that the input box has the focus on load and after each entry
    input.current.focus();
  }, []);


  async function handleSubmit(event) {
    event.preventDefault();
    // if there is no text in the the chat input box, do nothing.
    if (newMessage === "") {
      return;
    }
    
    const destLang = languageTranslate[currentContactId[0]];

    let translatedMessage = newMessage;
    try {
      const translatedResponse = await postTranslateText(newMessage, 'en', destLang);
      const text = translatedResponse?.payload?.text;
      translatedMessage = text;
    } catch (error) {
      console.error(error);
    }

    console.log(` Original Message: ` + newMessage + `\n Translated Message: ` + translatedMessage);
    // create the new message to add to Chats.
    const newMessageData = {
      contactId: currentContactId[0],
      username: agentUsername,
      content: <p>{newMessage}</p>,
      translatedMessage: <p>{translatedMessage}</p>, // set to {translatedMessage.TranslatedText} if using custom terminologies
    };
    // add the new message to the store
    addChat(prevMsg => [...prevMsg, newMessageData]);
    // clear the chat input box
    setNewMessage("");

    const session = retrieveValue(currentContactId[0]);

    function retrieveValue(key) {
      var value = "";
      for (var obj in props.session) {
        for (var item in props.session[obj]) {
          if (item === key) {
            value = props.session[obj][item];
            break;
          }
        }
      }
      return value;
    }
    sendMessage(session, translatedMessage);
  }



  return (
    <div className="chatroom">
      <h3>Translate - ({languageTranslate[currentContactId[0]]}) {getKeyByValue(languageOptions)}</h3>
      <ul className="chats" ref={messageEl}>
        {
          // iterate over the Chats, and only display the messages for the currently active chat session
          Chats
            .filter(chat => chat.contactId === currentContactId[0])
            .map(chat => {
              return <Message chat={chat} user={agentUsername} />
            })
        }
      </ul>
      <form className="input" onSubmit={handleSubmit} >
        <input
          ref={input}
          maxLength="1024"
          type="text"
          value={newMessage}
          onChange={e => setNewMessage(e.target.value)}
        />
        <input type="submit" value="Submit" />
      </form>

    </div>
  );
};


export default Chatroom;
