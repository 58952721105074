import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // headers: {
  //   'x-channel': process.env.REACT_APP_API_CHANNEL,
  //   'x-guid': process.env.REACT_APP_API_GUID,
  //   'x-api-key': process.env.REACT_APP_API_KEY,
  //   'x-correlationid': process.env.REACT_APP_API_CORRELATION_ID,
  //   'x-locale': process.env.REACT_APP_API_LOCALE,
  // },
});

export default api;